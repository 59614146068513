<template>
  <header class="header" role="banner">
    <div class="logo">
      <router-link to="/">
        <div style="display: flex; align-items: center">
          <img src="@/assets/logo.png" :alt="$t('header.appName')" class="logo-img mr-3" />
          <h1 class="app-name" style="font-size: 1.3rem">{{ $t('header.appName') }}</h1>
        </div>
      </router-link>
    </div>
      <nav class="nav" role="navigation" aria-label="主导航">
        <!-- Language Switcher -->
        <div class="language-switcher" v-if="showLanguageSwitcher" @click="toggleLanguageDropdown" ref="langSwitcher">
          <div class="lang-display" :class="{ 'is-open': isLangDropdownOpen }">
            <i class="fas fa-globe"></i>
            <span class="current-lang">{{ languageDisplayName }}</span>
          </div>
          <div class="lang-dropdown" v-show="isLangDropdownOpen">
            <div v-for="(name, code) in languageNames" 
                  :key="code" 
                  class="lang-option" 
                  @click="setLanguage(code, true)" 
                  :class="{ active: currentLanguage === code }">
              {{ name }}
            </div>
          </div>
        </div>
        
        <a href="/doc/docs" class="nav-link">{{ $t('header.docs') }}</a>
        <router-link to="/square" class="nav-link">{{ $t('header.square') }}</router-link>
        
        
        <template v-if="!isLoggedIn">
          <router-link to="/login" class="login-btn">{{ $t('header.login') }}</router-link>
        </template>
        <template v-else>
          <router-link to="/studio" class="nav-link">{{ $t('header.studio') }}</router-link>
          <div class="user-menu" ref="userMenu">
            <button @click="toggleUserDropdown" class="user-btn" :aria-label="$t('header.userMenu')">
              <i class="fas fa-user"></i>
            </button>
            <div class="user-dropdown" v-show="isUserDropdownOpen">
              <router-link to="/account" class="dropdown-item">
                <i class="fas fa-user-circle"></i>
                {{ $t('header.myAccount') }}
              </router-link>
              <button @click="handleLogout" class="dropdown-item">
                <i class="fas fa-sign-out-alt"></i>
                {{ $t('header.logout') }}
              </button>
            </div>
          </div>
        </template>
      </nav>
  </header>
</template>

<script>
import { ref, onMounted, onUnmounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { logout } from '@/api/auth';

export default {
  name: 'AppHeader',
  setup() {
    const router = useRouter();
    const { t, locale } = useI18n();

    const isLangDropdownOpen = ref(false);
    const isUserDropdownOpen = ref(false);
    const showLanguageSwitcher = ref(true);
    const currentLanguage = ref(locale.value);
    const languageAbbr = {
      'zh': '中',
      'en': 'En',
      // 'ko': '한',
      // 'es': 'Es',
      // 'fr': 'Fr',
      // 'de': 'De',
      // 'ja': '日',
      // 'ru': 'Ru',
      // 'ar': 'Ar',
      // 'pt': 'Pt'
    };
    const languageNames = {
      'zh': '中文',
      'en': 'English',
      // 'ko': '한국어',
      // 'es': 'Español',
      // 'fr': 'Français',
      // 'de': 'Deutsch',
      // 'ja': '日本語',
      // 'ru': 'Русский',
      // 'ar': 'العربية',
      // 'pt': 'Português'
    };

    const languageDisplayName = ref(languageAbbr[currentLanguage.value] || currentLanguage.value);

    const shouldUseChinese = () => {
      const hostname = window.location.hostname;
      // Only use non-Chinese for domains ending with paperspeech
      if (hostname.endsWith('paperspeech.com') || hostname === 'localhost') {
        return false;
      }
      // Use Chinese for all other domains including localhost
      return true;
    };

    const toggleLanguageDropdown = () => {
      isLangDropdownOpen.value = !isLangDropdownOpen.value;
    };

    const closeLanguageDropdown = (event) => {
      const langSwitcher = document.querySelector('.language-switcher')
      if (langSwitcher && !langSwitcher.contains(event.target)) {
        isLangDropdownOpen.value = false;
      }
    };

    const toggleUserDropdown = () => {
      isUserDropdownOpen.value = !isUserDropdownOpen.value;
    };

    const closeUserDropdown = (event) => {
      const userMenu = document.querySelector('.user-menu')
      if (userMenu && !userMenu.contains(event.target)) {
        isUserDropdownOpen.value = false;
      }
    };

    
    const handleLogout = async () => {
      try {
        await logout();
        router.push('/login');
      } catch (error) {
        console.error('Logout failed', error);
      }
    };

    const setLanguage = (lang, savePreference = false) => {
      locale.value = lang;
      currentLanguage.value = lang;
      languageDisplayName.value = languageAbbr[lang] || lang;
      if (savePreference) {
        localStorage.setItem('user-language', lang);
      }
    };

    const initializeLanguage = () => {
      // Check if we should use Chinese
      if (shouldUseChinese()) {
        setLanguage('zh');
        showLanguageSwitcher.value = false;  // Hide language switcher for Chinese
        return;
      }
      
      // For non-Chinese domains: use saved language or default to English
      showLanguageSwitcher.value = true;  // Show language switcher
      const savedLanguage = localStorage.getItem('user-language');
      setLanguage(savedLanguage || 'en');
    };

    const handleClickOutside = (event) => {
      closeLanguageDropdown(event);
      closeUserDropdown(event);
    };

    onMounted(() => {
      document.addEventListener('click', handleClickOutside);
      initializeLanguage();
    });

    onUnmounted(() => {
      document.removeEventListener('click', handleClickOutside);
    });

    return {
      isLangDropdownOpen,
      isUserDropdownOpen,
      showLanguageSwitcher,
      currentLanguage,
      languageAbbr,
      languageNames,
      languageDisplayName,
      isLoggedIn: computed(() => !!localStorage.getItem("access_token")),
      toggleLanguageDropdown,
      closeLanguageDropdown,
      toggleUserDropdown,
      closeUserDropdown,
      handleLogout,
      setLanguage,
      initializeLanguage,
      handleClickOutside,
      t
    };
  }
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.logo-img {
  height: 40px;
}

.nav {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.nav a {
  color: #64748b;
  text-decoration: none;
  transition: color 0.3s ease;
}

.nav a:hover {
  color: #3b82f6;
}

.login-btn, .logout-btn {
  color: #64748b;
  text-decoration: none;
  transition: color 0.3s ease;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.login-btn:hover, .logout-btn:hover {
  color: #3b82f6;
}

.language-switcher {
  position: relative;
  cursor: pointer;
  min-width: 60px;
}

.lang-display {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: 4px;
  background: var(--bg-secondary);
  transition: background-color 0.2s;
}

.current-lang {
  margin-left: 0.5rem;
  font-size: 0.9rem;
}

.lang-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 0.25rem;
  background-color: white;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 50;
  min-width: 6rem;
}

.lang-option {
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.lang-option:hover {
  background-color: #f7fafc;
}

.lang-option.active {
  background-color: #ebf5ff;
  color: #3b82f6;
}

.lang-select {
  display: none;
}

.user-menu {
  position: relative;
}

.user-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  color: #64748b;
  transition: color 0.3s ease;
}

.user-btn:hover {
  color: #3b82f6;
}

.user-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  min-width: 150px;
  z-index: 1000;
}

.dropdown-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  color: #64748b;
  text-decoration: none;
  transition: background-color 0.2s;
  cursor: pointer;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
}

.dropdown-item:hover {
  background-color: #f7fafc;
  color: #3b82f6;
}

.dropdown-item i {
  width: 1rem;
}

@media (max-width: 768px) {
  .nav {
    gap: 1rem;
  }

  .header {
    padding: 0.75rem 1rem;
  }

  .logo-img {
    height: 32px;
  }
  
}

@media (max-width: 480px) {
  .nav {
    gap: 0.5rem;
  }
}
</style>
