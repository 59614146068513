import axios from 'axios'

// const API_URL = process.env.VUE_APP_API_URL || 'http://localhost:8000'

// 添加请求拦截器
axios.interceptors.request.use(config => {
  const token = localStorage.getItem('access_token')
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

export async function login(email, password) {
  
  try {
    // const response = await axios.post(`${API_URL}/api/auth/login`, {
    const response = await axios.post('/api/auth/login', {
      email,
      password
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    
    
    // 存储 token 和用户信息
    localStorage.setItem('access_token', response.data.access_token)
    localStorage.setItem('user_uuid', response.data.user.uuid)
    localStorage.setItem('user_email', response.data.user.email)
      
    return response.data
  } catch (error) {
    console.error('完整错误信息:', error)
    throw error.response?.data || error
  }
}

export async function register(email, password, domain) {
  try {
    const response = await axios.post('/api/auth/register', {
      email,
      password,
      domain
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    console.error('注册错误:', error);
    throw error.response?.data || error;
  }
}

export async function logout() {
  try {
    
    await axios.post('/api/auth/logout')
    // 清除所有存储的信息
    localStorage.removeItem('access_token')
    localStorage.removeItem('user_uuid')
    localStorage.removeItem('user_email')
  } catch (error) {
    console.error('登出错误:', error)
    throw error
  }
} 