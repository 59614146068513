import { createRouter, createWebHistory } from 'vue-router'
import ResultsPage from '../views/Results.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/Register.vue')
  },


  {
    path: '/studio',
    name: 'TaskList',
    component: () => import('../views/TaskList.vue')
  },  
  {
    path: '/create/:file_uuid',
    name: 'CreateTask',
    component: () => import('../views/SubmitTask.vue')
  },
  {
    path: '/result/:file_uuid',
    name: 'Results',
    component: ResultsPage
  },
  {
    path: '/playlist/:playlist_uuid',
    name: 'Playlist',
    component: () => import('../views/Playlist.vue')
  },
  {
    path: '/square',
    name: 'Square',
    component: () => import('../views/Square.vue')
  },
  {
    path: '/pay',
    name: 'Pay',
    component: () => import('../views/Pay.vue')
  },
  {
    path: '/kol/:coupon_id',
    name: 'KOL',
    component: () => import('../views/KOL.vue')
  },
  {
    path: '/subscription',
    name: 'SubscriptionPage',
    component: () => import('../views/Subscription.vue')
  },

  {
    path: '/account',
    name: 'AccountPage',
    component: () => import('../views/Account.vue')
  },
  {
    path: "/team-plan",
    name: "TeamPlan",
    component: () => import('../views/TeamPlan.vue')
  },
  {
    path: "/feedlist",
    name: "FeedList",
    component: () => import('../views/FeedList.vue')
  },
  {
    path: "/feed/:feed_id",
    name: "FeedDetail",
    component: () => import('../views/FeedDetail.vue')
  }
  // 其他路由...
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
