import { createI18n } from 'vue-i18n'
import en from './en.json'
import zh from './zh.json'
import ko from './ko.json'
import es from './es.json'
import fr from './fr.json'
import de from './de.json'
import ja from './ja.json'
import ru from './ru.json'
import ar from './ar.json'
import pt from './pt.json'

const i18n = createI18n({
  legacy: false, // you must set `false`, to use Composition API
  locale: 'zh', // set default locale
  fallbackLocale: 'en',
  messages: {
    en,
    zh,
    ko,
    es,
    fr,
    de,
    ja,
    ru,
    ar,
    pt
  }
})

export default i18n
