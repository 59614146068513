<template>
  <div>
    <app-header></app-header>
    <div class="container mx-auto px-4 py-8 max-w-4xl">
      <div v-if="resultData">
        <div class="paper-info section-spacing">
          <h2 class="title">{{ resultData.paper_meta.title }}</h2>
          <p>
            <strong>{{ $t('results.paper.author') }}:</strong>
            {{ resultData.paper_meta.author ? resultData.paper_meta.author : $t('results.paper.unknown') }}
          </p>
          <p><strong>{{ $t('results.paper.affiliation') }}:</strong> {{ resultData.paper_meta.affiliation || $t('results.paper.unknown') }}</p>
          <p><strong>{{ $t('results.paper.journal') }}:</strong> {{ resultData.paper_meta.journal || $t('results.paper.unknown') }}</p>

          <p>
            <strong>{{ $t('results.paper.keywords') }}:</strong>
            {{
              resultData.paper_meta.keywords
                ? resultData.paper_meta.keywords.join(", ")
                : $t('results.paper.noKeywords')
            }}
          </p>
        </div>
        <video controls class="section-spacing">
          <source :src="`/${resultData.video_path}`" type="video/mp4" />
          <track
            :src="`/${vttPath}`"
            kind="subtitles"
            :label="$t('results.video.subtitleLang')"
            srclang="zh"
            default
          />
          {{ $t('results.video.unsupported') }}
        </video>
        <div class="section-spacing">
          <p><strong>{{ $t('results.paper.abstract') }}:</strong> {{ resultData.paper_meta.abstract || $t('results.paper.noAbstract') }}</p>
          <div class="flex flex-wrap gap-2 mt-4">
            <button
              class="btn bg-blue-gray-100 text-blue-gray-800 font-semibold py-2 px-4 rounded hover:bg-blue-gray-200 transition duration-300"
              @click="downloadFile(scriptPath)"
              v-if="scriptPath"
            >
              <i class="fas fa-download mr-2"></i>{{ $t('results.download.script') }}
            </button>
            <button
              class="btn bg-blue-gray-100 text-blue-gray-800 font-semibold py-2 px-4 rounded hover:bg-blue-gray-200 transition duration-300"
              @click="downloadFile(figuresPath)"
              v-if="figuresPath"
            >
              <i class="fas fa-download mr-2"></i>{{ $t('results.download.explanation') }}
            </button>
            <button
              class="btn bg-blue-gray-100 text-blue-gray-800 font-semibold py-2 px-4 rounded hover:bg-blue-gray-200 transition duration-300"
              @click="downloadFile(pptPath)"
              v-if="pptPath"
            >
              <i class="fas fa-download mr-2"></i>{{ $t('results.download.ppt') }}
            </button>
            <button
              class="btn bg-blue-gray-100 text-blue-gray-800 font-semibold py-2 px-4 rounded hover:bg-blue-gray-200 transition duration-300"
              @click="downloadFile(pdfPath)"
              v-if="pdfPath"
            >
              <i class="fas fa-download mr-2"></i>{{ $t('results.download.pdf') }}
            </button>
            <button
              class="btn bg-blue-gray-100 text-blue-gray-800 font-semibold py-2 px-4 rounded hover:bg-blue-gray-200 transition duration-300"
              @click="downloadFile(resultData.video_path)"
              v-if="resultData.video_path"
            >
              <i class="fas fa-download mr-2"></i>{{ $t('results.download.video') }}
            </button>
            <button
              class="btn bg-blue-gray-100 text-blue-gray-800 font-semibold py-2 px-4 rounded hover:bg-blue-gray-200 transition duration-300"
              @click="downloadFile(vttPath)"
              v-if="resultData.video_path"
            >
              <i class="fas fa-download mr-2"></i>{{ $t('results.download.subtitle') }}
            </button>
            <button
              @click="copyToClipboard"
              class="btn btn-secondary bg-gray-300 text-gray-800 font-semibold py-2 px-4 rounded hover:bg-gray-400 transition duration-300"
              style="width: 100px"
            >
              <i class="fas fa-share-alt"></i> {{ $t('results.share.button') }}
            </button>
          </div>
          <p v-if="copySuccess" class="copy-notification">{{ $t('results.share.success') }}</p>
        </div>

        <details class="section-spacing" open>
          <summary>{{ $t('results.preview.script.title') }}</summary>
          <div class="slide-container">
            <div class="slide-titles">
              <div
                v-for="(title, index) in resultData.slide_titles"
                :key="index"
                :class="{ active: activeSlideIndex === index }"
                @click="activeSlideIndex = index"
              >
                {{ title }}
              </div>
            </div>
            <div class="slide-script">
              <p>{{ resultData.slide_scripts[activeSlideIndex] }}</p>
            </div>
          </div>
        </details>

        <details class="section-spacing">
          <summary>{{ $t('results.preview.figures.title') }}</summary>
          <div class="table-responsive">
            <table class="table border-collapse border border-gray-300">
              <tbody>
                <tr
                  v-for="(imgPath, index) in resultData.img_paths"
                  :key="index"
                  class="border border-gray-300"
                >
                  <td class="w-50 border border-gray-300">
                    <img
                      :src="`/${imgPath}`"
                      :alt="$t('results.modal.imageAlt')"
                      class="img-fluid"
                      style="max-height: 500px; width: auto; min-width: 300px"
                      @click="showImageModal(imgPath)"
                    />
                  </td>
                  <td class="w-50 border border-gray-300">
                    <div class="p-3">
                      <p class="mb-2">
                        <strong>{{ $t('results.preview.figures.caption') }}:</strong> {{ resultData.img_captions[index] }}
                      </p>
                      <p class="mb-0">
                        <strong>{{ $t('results.preview.figures.script') }}:</strong> {{ resultData.img_scripts[index] }}
                      </p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </details>

        <details class="section-spacing">
          <summary>{{ $t('results.preview.ppt.title') }}</summary>
          <div v-if="pdfPath" class="pdf-viewer">
            <span class="text-gray-600 text-lg">{{ $t('results.preview.ppt.note') }}</span>
            <iframe
              v-if="pdfPath"
              :src="`/${pdfPath}`"
              width="100%"
              height="600px"
              style="border: none; max-width: 100%; max-height: 90vh"
            ></iframe>
          </div>
        </details>

        <div v-if="isModalVisible" class="modal" @click="closeImageModal">
          <div class="modal-content" @click.stop>
            <span class="close" @click="closeImageModal">&times;</span>
            <img :src="`/${currentImagePath}`" :alt="$t('results.modal.imageAlt')" class="large-image" />
          </div>
        </div>
      </div>
      <div v-else-if="error" class="section-spacing">
        <p>{{ error }}</p>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</template>

<script>
import axios from "axios";
import AppHeader from "../components/Header.vue";
import AppFooter from "../components/Footer.vue";

export default {
  name: "ResultsPage",
  computed: {
    isLoggedIn() {
      return !!localStorage.getItem("access_token");
    },
    pdfPath() {
      if (!this.resultData?.video_path) return null;
      return this.resultData.video_path.replace('_ppt.mp4', '_ppt.pdf');
    },
    pptPath() {
      if (!this.resultData?.video_path) return null;
      return this.resultData.video_path.replace('_ppt.mp4', '_ppt.pptx');
    },
    scriptPath() {
      if (!this.resultData?.video_path) return null;
      return this.resultData.video_path.replace('_ppt.mp4', '_scripts.docx');
    },
    figuresPath() {
      if (!this.resultData?.video_path) return null;
      return this.resultData.video_path.replace('_ppt.mp4', '_imgs.docx');
    },
    vttPath() {
      if (!this.resultData?.video_path) return null;
      return this.resultData.video_path.replace('_ppt.mp4', '_ppt.vtt');
    }
  },
  components: {
    AppHeader,
    AppFooter,
  },
  data() {
    return {
      resultData: null,
      error: null,
      activeSlideIndex: 0,
      isModalVisible: false,
      currentImagePath: "",
      copySuccess: false,
    };
  },
  created() {
    this.fetchResultData();
  },

  methods: {
    async fetchResultData() {
      const fileUuid = this.$route.params.file_uuid;
      const apiUrl = this.isLoggedIn
        ? `/api/result/${fileUuid}`
        : `/api/result/public/${fileUuid}`;
      try {
        const response = await axios.get(apiUrl);
        this.resultData = response.data;
        if (this.resultData.ppt_path) {
          this.resultData.script_path = this.resultData.ppt_path.replace(
            "_ppt.pptx",
            "_scripts.docx"
          );
          this.resultData.detailed_explanation_path = this.resultData.ppt_path.replace(
            "_ppt.pptx",
            "_imgs.docx"
          );
        }
        console.log("Video Path:", this.resultData.video_path);
      } catch (error) {
        this.error = "无法获取结果信息";
        console.error(error);
      }
    },
    showImageModal(imgPath) {
      this.currentImagePath = imgPath;
      this.isModalVisible = true;
    },
    closeImageModal() {
      this.isModalVisible = false;
    },
    downloadFile(filePath) {
      const link = document.createElement("a");
      link.href = `/${filePath}`;
      link.download = filePath.split("/").pop();
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    copyToClipboard() {
      const url = window.location.href; // 获取当前页面的 URL
      navigator.clipboard
        .writeText(url)
        .then(() => {
          this.copySuccess = true; // 设置复制成功状态
          if (this.resultData.is_published === true) {
            this.copySuccessMessage = "链接已复制到剪切板！";
          } else {
            this.copySuccessMessage =
              "链接已复制到剪切板, 目前视频是您专属, 请投稿到播放列表后其他人才可见。";
          }
          setTimeout(() => {
            this.copySuccess = false; // 3秒后隐藏提示
          }, 3000);
        })
        .catch((err) => {
          console.error("复制失败:", err);
        });
    },
  },
};
</script>

<style scoped>
.results {
  margin: 0 auto;
}

app-header,
app-footer {
  width: 100%;
}

.section-spacing {
  margin-bottom: 20px;
}

.image-gallery {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.image-caption-script {
  display: flex;
  align-items: stretch;
  gap: 20px;
}

.image-column {
  flex: 1;
  max-width: 50%;
  display: flex;
  align-items: center;
}

.image-column img {
  width: 100%;
  height: auto;
  max-height: 200px; /* 设置最大高度 */
  object-fit: contain; /* 保持等比缩放 */
  border: 1px solid #ccc;
  border-radius: 4px;
}

.caption-script-column {
  flex: 1;
  max-width: 50%;
}

details {
  cursor: pointer;
  margin-bottom: 20px;
}

summary {
  font-weight: bold;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f5f5f5; /* 灰色背景 */
  color: #666; /* 深灰色文字 */
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: 1px solid #e0e0e0;
}

summary:hover {
  background-color: #e0e0e0; /* 鼠标悬停时的深色 */
}

.pdf-viewer {
  margin-top: 20px;
}

.slide-container {
  display: flex;
  gap: 20px;
}

.slide-titles {
  flex: 1;
  max-height: 250px; /* 设置最大高度 */
  overflow-y: auto; /* 允许垂直滚动 */
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 10px;
  background-color: #fafafa; /* 更浅的灰色背景 */
}

.slide-titles div {
  padding: 5px;
  cursor: pointer;
}

.slide-titles div.active {
  background-color: #e0e0e0; /* 选中时的灰色 */
  color: #333; /* 深灰色文字 */
}

.slide-script {
  flex: 2;
  max-height: 250px; /* 设置最大高度 */
  overflow-y: auto; /* 允许垂直滚动 */
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: #fafafa; /* 更浅的灰色背景 */
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-content {
  position: relative;
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 800px;
}

.close {
  position: absolute;
  top: 10px;
  right: 25px;
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.large-image {
  width: 100%;
  height: auto;
  max-width: 90vw; /* 设置最大宽度为视口宽度的90% */
  max-height: 90vh; /* 设置最大高度为视口高度的90% */
}

.title {
  font-size: 1.8rem; /* 增大标题字体大小 */
  font-weight: bold; /* 可选：加粗标题 */
  margin-bottom: 20px; /* 增加标题与其他信息之间的行距 */
}

.paper-info p {
  margin-bottom: 10px; /* 调整段落之间的行距 */
}

.copy-notification {
  margin-top: 10px;
  color: green; /* 提示文本颜色 */
}

.share-section {
  display: flex;
  align-items: center; /* 保持在同一行 */
}

.share-section p {
  margin-right: 10px; /* 添加右边距以确保间距 */
}

.btn {
  display: inline-block;
  padding: 5px 10px;
  background-color: #f0f4f8; /* 改为浅蓝灰色 */
  border: 2px solid #cbd5e1; /* 改为中蓝灰色 */
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  color: #334155; /* 深蓝灰色文字 */
}

.btn:hover {
  background-color: #cbd5e1; /* 悬停时改为中蓝灰色 */
  color: #1e293b; /* 悬停时改为更深蓝灰色 */
}

@media (max-width: 768px) {
  /* 针对移动端的样式 */
  .table-responsive {
    display: block; /* 使表格在移动端显示为块级元素 */
  }
  .table {
    display: flex;
    flex-direction: column; /* 使表格内容垂直排列 */
  }
  .table tr {
    display: flex;
    flex-direction: column; /* 每一行也垂直排列 */
    margin-bottom: 20px; /* 行间距 */
  }
  .table td {
    display: flex;
    flex-direction: column; /* 每个单元格内容垂直排列 */
    align-items: center; /* 内容居中 */
  }
  .share-section {
    flex-direction: column; /* 将分享按钮和简介分为两行 */
    align-items: flex-start; /* 左对齐 */
  }
  .share-section p {
    margin-bottom: 10px; /* 添加底部边距以确保间距 */
  }
}
</style>
