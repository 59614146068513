<template>
  <footer class="footer">
    <div class="footer-content">
      <div class="footer-section">
        <h3>{{ $t('footer.about.title') }}</h3>
        <p>{{ $t('footer.about.description') }}</p>
      </div>

      <div class="footer-section">
        <h3>{{ $t('footer.quickLinks.title') }}</h3>
        <ul>
          <li><a href="#">{{ $t('footer.quickLinks.tutorial') }}</a></li>
          <li><a href="#">{{ $t('footer.quickLinks.faq') }}</a></li>
        </ul>
      </div>

      <div class="footer-section">
        <h3>{{ $t('footer.contact.title') }}</h3>
        <ul>
          <li>{{ $t('footer.contact.email') }}paperwithvideo@gmail.com</li>
          <li><a href="https://discord.gg/EGNJjwjMAN" target="_blank" class="discord-link mt-2">
            <svg class="discord-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="currentColor">
              <path d="M20.317 4.37a19.791 19.791 0 0 0-4.885-1.515a.074.074 0 0 0-.079.037c-.21.375-.444.864-.608 1.25a18.27 18.27 0 0 0-5.487 0a12.64 12.64 0 0 0-.617-1.25a.077.077 0 0 0-.079-.037A19.736 19.736 0 0 0 3.677 4.37a.07.07 0 0 0-.032.027C.533 9.046-.32 13.58.099 18.057a.082.082 0 0 0 .031.057a19.9 19.9 0 0 0 5.993 3.03a.078.078 0 0 0 .084-.028a14.09 14.09 0 0 0 1.226-1.994a.076.076 0 0 0-.041-.106a13.107 13.107 0 0 1-1.872-.892a.077.077 0 0 1-.008-.128a10.2 10.2 0 0 0 .372-.292a.074.074 0 0 1 .077-.01c3.928 1.793 8.18 1.793 12.062 0a.074.074 0 0 1 .078.01c.12.098.246.198.373.292a.077.077 0 0 1-.006.127a12.299 12.299 0 0 1-1.873.892a.077.077 0 0 0-.041.107c.36.698.772 1.362 1.225 1.993a.076.076 0 0 0 .084.028a19.839 19.839 0 0 0 6.002-3.03a.077.077 0 0 0 .032-.054c.5-5.177-.838-9.674-3.549-13.66a.061.061 0 0 0-.031-.03zM8.02 15.33c-1.183 0-2.157-1.085-2.157-2.419c0-1.333.956-2.419 2.157-2.419c1.21 0 2.176 1.096 2.157 2.42c0 1.333-.956 2.418-2.157 2.418zm7.975 0c-1.183 0-2.157-1.085-2.157-2.419c0-1.333.955-2.419 2.157-2.419c1.21 0 2.176 1.096 2.157 2.42c0 1.333-.946 2.418-2.157 2.418z"/>
            </svg>
            Discord
          </a></li>
          <li>
            <button @click="openWeChatModal" class="mt-2 wechat-button">
              {{ $t('footer.contact.wechatGroup') }}
            </button>
          </li>
        </ul>
      </div>
    </div>

    <div class="footer-bottom">
      <p class="copyright">{{ $t('footer.bottom.copyright', { year: currentYear }) }}</p>
      <div class="footer-links">
        <a href="#">{{ $t('footer.bottom.terms') }}</a>
        <span class="link-separator">|</span>
        <a href="#">{{ $t('footer.bottom.privacy') }}</a>
      </div>
    </div>

    <!-- WeChat Group QR Code Modal -->
    <div
      v-if="showWeChatModal"
      class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
    >
      <div class="bg-white p-6 rounded-lg w-96 shadow-lg relative">
        <h3 class="text-2xl mb-4 text-center" style="color: black">
          {{ $t('footer.contact.wechatModal.title') }}
        </h3>
        <img
          src="/static/wechat.jpg"
          alt="WeChat Group QR Code"
          class="rounded-lg max-w-full h-auto"
        />
        <button
          @click="closeWeChatModal"
          class="absolute top-2 right-4 text-gray-600 hover:text-gray-800"
        >
          ×
        </button>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
  data() {
    return {
      showWeChatModal: false,
      currentYear: new Date().getFullYear(),
    };
  },
  methods: {
    openWeChatModal() {
      this.showWeChatModal = true;
    },
    closeWeChatModal() {
      this.showWeChatModal = false;
    },
  },
};
</script>

<style scoped>
.footer {
  background-color: #1e293b;
  color: #f8fafc;
  padding: 4rem 2rem 2rem;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 3rem;
  margin-bottom: 3rem;
}

.footer-section h3 {
  color: white;
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.footer-section p,
.footer-section a,
.footer-section li {
  color: #cbd5e1;
}

.footer-section a:hover {
  color: white;
}

.footer-bottom {
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 2rem;
  border-top: 1px solid #334155;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.footer-links a {
  color: #cbd5e1;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: white;
}

.link-separator {
  margin: 0 8px;
  color: #666;
}

/* WeChat Group button styles */
.wechat-button {
  background-color: #10b981;
  color: #ffffff;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.wechat-button:hover {
  background-color: #059669;
}

/* Discord link styles */
.discord-link {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  color: inherit;
  text-decoration: none;
  transition: opacity 0.2s;
}

.discord-link:hover {
  opacity: 0.8;
}

.discord-icon {
  width: 20px;
  height: 20px;
}

/* WeChat Group QR Code Modal styles */
.fixed {
  position: fixed;
}

.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.bg-black {
  background-color: #000000;
}

.bg-opacity-50 {
  background-color: rgba(0, 0, 0, 0.5); /* Ensure only background color is semi-transparent */
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.bg-white {
  background-color: #ffffff;
}

.p-6 {
  padding: 1.5rem;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.w-96 {
  width: 300px;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mt-4 {
  margin-top: 1rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.bg-gray-800 {
  background-color: #2d3748;
}

.text-white {
  color: #ffffff;
}

.hover\:bg-gray-700:hover {
  background-color: #4a5568;
}

/* Close button styles */
button.absolute {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

button.absolute:focus {
  outline: none;
}
</style>
