import { createApp } from 'vue'
import { createHead } from '@vueuse/head'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import 'tailwindcss/tailwind.css'

// Vue.config.productionTip = false (removed, as it's not needed in Vue 3)

const app = createApp(App)
const head = createHead()

app.use(router)
app.use(head)
app.use(i18n)
app.use(ElementPlus)
app.mount('#app')