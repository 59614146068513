<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  mounted() {
    const hostname = window.location.hostname;
    document.title = hostname.includes('paperspeech') 
      ? 'PaperSpeech'
      : '论文说-一键生成论文视频解说';
  },
};
</script>

<style>
#app {
  font-family: "PingFang SC", "Microsoft YaHei", sans-serif;
  color: #2c3e50;
}
</style>
